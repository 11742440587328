<template>
  <div class="body" >
    <div class="plat-one">
      <img class="img1" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/%E5%9B%BE4.jpg" alt="">
      <img class="img2 animate__animated animate__fadeInDown" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1/%E5%AD%974.png" alt="">
      <el-button @click="startFootFrom" class="linshibtn" type="primary">立即抢占海量客户</el-button>
    </div>
    <!-- <zhe></zhe> -->
    <!-- 底部弹起表单 -->
    <div class="from-foot " v-show="isFootFrom">
      <div class="from-foot-from animate__animated animate__fadeInUp">
        <h3 @click="closeFootFrom">×</h3>
        <el-form :model="fromData" class="part-foot-from" label-position="left" label-width="50px" ref="fromData"
          :rules=rules>
          <el-form-item label="" prop="user" class="from-child">
            <el-input v-model.trim="fromData.user" placeholder="姓名"></el-input>
          </el-form-item>
          <el-form-item label="" prop="phone" class="from-child">
            <el-input v-model.trim="fromData.phone" placeholder="手机"></el-input>
          </el-form-item>
          <el-form-item label="" prop="name" class="from-child">
            <el-input v-model.trim="fromData.name" placeholder="公司名称"></el-input>
          </el-form-item>
          <el-form-item label="" prop="business" class="from-child">
          <el-input v-model.trim="fromData.business" placeholder="行业分类（不清楚可不填）"></el-input>
        </el-form-item>
          <el-button class="linshibtn" @click="submitFromData('fromData')" type="primary">立即咨询</el-button>
        </el-form>
      </div>
    </div>
    <!-- 第二部分 线上场景-->
    <div class="plat-two">
      <div class="plat-two-top">
        <p class="plat-two-top-p1">OPPO倾力打造高覆盖优质流量</p>
        <p class="plat-two-top-p2">拥有三亿优质的OPPO用户，自有流量日均曝光高达40亿，围绕多场景结合</p>
      </div>
      <div class="plat-two-bottom">
        <p class="plat-two-bottom-p1">线上场景</p>
        <div v-show="isplat == 0">
          <img class="plat-two-bottom-img1 animate__animated animate__fadeIn"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/1%E6%96%B0%E9%97%BB%E5%92%A8%E8%AF%A2.jpg"
            alt="">
          <img class="plat-two-bottom-img2 animate__animated animate__fadeInLeft"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%B0%8F1.png" alt="">
        </div>
        <div v-show="isplat == 1">
          <img class="plat-two-bottom-img1 animate__animated animate__fadeIn"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/5%E5%B7%A5%E5%85%B7%E5%BA%94%E7%94%A8.jpg"
            alt="">
          <img class="plat-two-bottom-img2 animate__animated animate__fadeInLeft"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%B0%8F2.png" alt="">
        </div>
        <div v-show="isplat == 2">
          <img class="plat-two-bottom-img1 animate__animated animate__fadeIn"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/3%E4%BC%91%E9%97%B2%E5%A8%B1%E4%B9%90.jpg"
            alt="">
          <img class="plat-two-bottom-img2 animate__animated animate__fadeInLeft"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%B0%8F3.png" alt="">
        </div>
        <div v-show="isplat == 3">
          <img class="plat-two-bottom-img1 animate__animated animate__fadeIn"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/4%E7%94%9F%E6%B4%BB%E5%BA%94%E7%94%A8.jpg"
            alt="">
          <img class="plat-two-bottom-img2 animate__animated animate__fadeInLeft"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%B0%8F4.png" alt="">
        </div>
        <div v-show="isplat == 4">
          <img class="plat-two-bottom-img1 animate__animated animate__fadeIn"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/5%E6%89%8B%E6%9C%BA%E7%BE%8E%E5%8C%96.jpg"
            alt="">
          <img class="plat-two-bottom-img2 animate__animated animate__fadeInLeft"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%B0%8F5.png" alt="">
        </div>
        <div class="plat-two-bottom-text">
          <p :class="isplat == 0 ? 'plat-two-bottom-text-active' : ''" @click="isplat = 0">新闻资讯</p>
          <p :class="isplat == 1 ? 'plat-two-bottom-text-active' : ''" @click="isplat = 1">工具应用</p>
          <p :class="isplat == 2 ? 'plat-two-bottom-text-active' : ''" @click="isplat = 2">休闲娱乐</p>
          <p :class="isplat == 3 ? 'plat-two-bottom-text-active' : ''" @click="isplat = 3">生活应用</p>
          <p :class="isplat == 4 ? 'plat-two-bottom-text-active' : ''" @click="isplat = 4">手机美化</p>
        </div>
      </div>
      <el-button @click="startFootFrom" class="linshibtn" type="primary">立即获取专属流量</el-button>
    </div>
    <!-- 第三部分 线下场景 -->
    <div class="plat-three">

      <div class="plat-three-bottom">
        <p class="plat-three-bottom-p1">线下场景</p>
        <p class="plat-three-bottom-p2">充分发挥OPPO独有的流量资源优势，将推广信息高效、准确、广度地触达终端用户</p>
        <div v-show="isPlat2 == 0">
          <img class="plat-three-bottom-img1 animate__animated animate__fadeIn"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E9%97%A8%E5%BA%97.jpg" alt="">
        </div>
        <div v-show="isPlat2 == 1">
          <img class="plat-three-bottom-img1 animate__animated animate__fadeIn"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%95%86%E5%9C%BA.jpg" alt="">
        </div>
        <div v-show="isPlat2 == 2">
          <img class="plat-three-bottom-img1 animate__animated animate__fadeIn"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%8A%9E%E5%85%AC%E6%A5%BC.jpg" alt="">
        </div>
        <div v-show="isPlat2 == 3">
          <img class="plat-three-bottom-img1 animate__animated animate__fadeIn"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E8%B6%85%E5%B8%82.jpg" alt="">
        </div>
        <div v-show="isPlat2 == 4">
          <img class="plat-three-bottom-img1 animate__animated animate__fadeIn"
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E9%97%A8%E5%BA%97.jpg" alt="">
        </div>
        <div class="plat-three-bottom-text">
          <p :class="isPlat2 == 0 ? 'plat-three-bottom-text-active' : ''" @click="isPlat2 = 0">OPPO线下</p>
          <p :class="isPlat2 == 1 ? 'plat-three-bottom-text-active' : ''" @click="isPlat2 = 1">商场</p>
          <p :class="isPlat2 == 2 ? 'plat-three-bottom-text-active' : ''" @click="isPlat2 = 2">办公楼</p>
          <p :class="isPlat2 == 3 ? 'plat-three-bottom-text-active' : ''" @click="isPlat2 = 3">超市</p>
          <p :class="isPlat2 == 4 ? 'plat-three-bottom-text-active' : ''" @click="isPlat2 = 4">智能电视</p>
        </div>
      </div>
      <el-button @click="startFootFrom" class="linshibtn" type="primary">立即获取专属流量</el-button>
    </div>
    <!-- 第四部分 多场景-->
    <div class="plat-four">
      <div class="plat-four-top">
        <p class="plat-four-top-p1">深入OPPO手机终端，围绕多场景结合</p>
        <p class="plat-four-top-p2">深度定制的商业化平台系统，基于OPPO用户使用OPPO手机的各个场景，实现精准推送和精准营销</p>
      </div>
      <div class="plat-four-top-img">
        <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/bg%20%281%29.png" alt="">
      </div>
      <ul class="plat-four-icon">
        <li class="plat-four-icon-child">
          <img
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/2%E8%BD%AF%E4%BB%B6%E5%95%86%E5%BA%97.png"
            alt="">
        </li>
        <li class="plat-four-icon-child">
          <img
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/3%E4%B8%BB%E9%A2%98%E5%95%86%E5%BA%97.png"
            alt="">
        </li>
        <li class="plat-four-icon-child">
          <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/4%E5%A4%A9%E6%B0%94.png" alt="">
        </li>
        <li class="plat-four-icon-child">
          <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/5%E8%A7%86%E9%A2%91.png" alt="">
        </li>
        <li class="plat-four-icon-child">
          <img
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/6%E5%85%A8%E5%B1%80%E6%90%9C%E7%B4%A2.png"
            alt="">
        </li>
        <li class="plat-four-icon-child">
          <img
            src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/7%E6%89%8B%E6%9C%BA%E9%94%81%E5%B1%8F.png"
            alt="">
        </li>
      </ul>
      <el-button @click="startFootFrom" class="linshibtn" type="primary">立即获取专属流量</el-button>
    </div>
    <!-- 第五部分 流量分布 -->
    <div class="plat-five">
      <div class="plat-five-top">
        <p class="plat-five-top-p1">严格把关流量渠道，保证优质的用户流量</p>
        <p class="plat-five-top-p2">充分发挥OPPO独有的流量优势，各产品组成的产品矩阵提升整体流量效果和质量</p>
      </div>
      <ul class="plat-five-bottom">
        <li class="plat-five-bottom-child">
          <img class="plat-five-bottom-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%B9%B4%E9%BE%84.jpg" alt="">
          <p class="plat-five-bottom-p">用户年龄分布</p>
        </li>
        <li class="plat-five-bottom-child">
          <img class="plat-five-bottom-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E5%9C%B0%E5%9F%9F.jpg" alt="">
          <p class="plat-five-bottom-p">用户地域分布</p>
        </li>  <li class="plat-five-bottom-child">
          <img class="plat-five-bottom-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E6%97%B6%E9%95%BF.jpg" alt="">
          <p class="plat-five-bottom-p">用户使用时长分布</p>
        </li>  <li class="plat-five-bottom-child">
          <img class="plat-five-bottom-img" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E7%94%A8%E6%88%B7%E7%94%BB%E5%83%8F.jpg" alt="">
          <p class="plat-five-bottom-p">用户画像</p>
        </li>
      </ul>
      <el-button @click="startFootFrom" class="linshibtn" type="primary">立即获取专属流量</el-button>
    </div>
    <!-- 第七部分  广告投放 -->
    <div class="plat-seven">
      <div class="plat-seven-top">
        <p class="plat-seven-top-p1">投放操作简单，费用清晰</p>
        <p class="plat-seven-top-p2">完整透明的投放手册与营销学堂，费用清晰，一目了然，做到点对点教学，新手也能轻松get</p>
      </div>
      <div class="plat-seven-bottom">
        <img class="animate__animated animate__zoomIn " v-show="isSeven==0" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E6%8A%95%E6%94%BE%E6%8C%87%E5%8D%97.jpg" alt="">
        <img class="animate__animated animate__zoomIn " v-show="isSeven==1" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E8%90%A5%E9%94%80%E5%B9%B3%E5%8F%B0.jpg" alt="">
        <img class="animate__animated animate__zoomIn " v-show="isSeven==2" src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/%E6%8A%95%E6%94%BE%E6%88%90%E6%9C%AC.jpg" alt="">
        <div class="plat-seven-text" @click="isSeven=0" :class="isSeven==0?'plat-seven-text-active':''">
          <p class="plat-seven-text-p1">投放指南</p>
          <p class="plat-seven-text-p2">简单明了的投放流程说明，针对用户的不同投放需求，都有具体推广教程</p>
        </div>
        <div class="plat-seven-text"  @click="isSeven=1" :class="isSeven==1?'plat-seven-text-active':''">
          <p class="plat-seven-text-p1">营销学堂</p>
          <p class="plat-seven-text-p2">包含了运营多方面的基础知识，让用户不盲目投放，满足用户不同层次的学习要求</p>
        </div>
        <div class="plat-seven-text"  @click="isSeven=2" :class="isSeven==2?'plat-seven-text-active':''">
          <p class="plat-seven-text-p1">投放成本</p>
          <p class="plat-seven-text-p2">从用户预算出发，有效控制成本预算</p>
        </div>
      </div>
      <el-button @click="startFootFrom" class="linshibtn" type="primary">立即获取专属流量</el-button>
    </div>
        <!-- 表单提交后的提示信息 -->
        <div class="subSuccess " v-show="isSuss">
      <div class="message animate__animated animate__fadeInDown">
        <h2 @click="changeIsSuss">×</h2>
        <img src="https://xjhdbucket.oss-cn-hangzhou.aliyuncs.com/m.zjxjhd/img/icon-success.png" alt="">
        <div class="mss-text">
          <h3 class="mess-text-p">信息提交已成功</h3>
          <p>您的专属顾问会在一个工作日内联系您，请您保持电话畅通</p>
          <p>信息提交已成功</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import zhe from '@/components/zhe/zhe.vue';
import { submitData } from '@/api/http'
export default {
  // components: { zhe },
  data() {
    return {
      isSuss:false,
      isFootFrom: false,
      isplat: 0,
      isPlat2: 0,
      isSeven:0,
      fromData: {
        user: '',
        phone: '',
        name: '',
        business:""
      },
      rules: {
        user: [
          { required: true, message: "请输入您的姓名", trigger: "blur" }
        ],
        phone: [
          { required: true, message: "请输入您的联系方式", trigger: "blur" },
          { pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/, message: "必须输入正确的电话号码", trigger: "blur" },
        ],
        name: [
          { required: true, message: "请输入您的公司名称", trigger: "blur" }
        ]
      },
    }
  },
  methods: {
    changeIsSuss(){
      this.isSuss=!this.isSuss
    },
    closeFootFrom() {
      this.isFootFrom = !this.isFootFrom
    },
    startFootFrom() {
      this.isFootFrom = !this.isFootFrom
    },
    submitFromData(fromData) {
      this.$refs[fromData].validate(async (valid) => {
        if (valid) {
          // 当数据有效时
          console.log(this.fromData);
          fromData=this.fromData
          let res = submitData(fromData).then(res => {
            // console.log('res',res.data);
          })
           // 弹起消息提示框
           this.isSuss=true
            // 清空表单数据
            this.$refs.fromData.resetFields()
            // 关闭表单
            this.isFootFrom=false
        }else{
            this.$message.error('提交失败')
        }
      })
  
    },
  },

}
</script>

<style lang="less">
.body {
  width: 100vw;
  margin: 0 auto;

  .plat-one {
    position: relative;

    .linshibtn {
      position: absolute;
      left: 30%;
      top: 70%;

    }

    width: 100vw;
    height: 100vw;
    margin-top: 10vw;

    .img1 {
      width: 100%;
      height: 100%;
    }
     .img2{
      width: 92vw;
    height: 24vw;
    position: absolute;
    top: 13vw;
    left: 4vw
    }

  }
}

.from-foot {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.3);


  .from-foot-from {

    // text-align: right;
    h3 {
      text-align: right;
    }

    width: 100vw;
    height: 100vw;
    background-color: #fff;
    position: fixed;
    bottom: 0;

    .part-foot-from {
      margin-top: 10vw;

      .from-child {
        width: 80vw;
      }
    }

  }
}

// 第二部分  线上场景
.plat-two {
  margin-top: 10vw;
  width: 100vw;
  height: 100vw;

  .linshibtn {
    margin-top: -5vw;
    width: 188px;
  }

  // background-color: greenyellow;
  .plat-two-top {
    width: 80%;
    margin: 0 auto;
    height: 20vw;

    .plat-two-top-p1 {
      color: #000;
      font-size: 18px;
      font-weight: 600;

    }

    .plat-two-top-p2 {
      margin-top: 5vw;
      color: #919191;
      font-size: 13px;
    }
  }

  .plat-two-bottom {
    width: 100vw;
    height: 70vw;
    margin-top: 10vw;
    position: relative;

    .plat-two-bottom-p1 {
      font-weight: 600;
      margin-bottom: 5vw;
    }

    .plat-two-bottom-img1 {
      width: 100vw;
      height: 40vw;
    }

    .plat-two-bottom-img2 {
      width: 28vw;
      height: 18vw;
      position: absolute;
      left: 10vw;
      top: 15vw;
    }
  }

  .plat-two-bottom-text {
    width: 100vw;
    height: 8vw;
    display: flex;
    justify-content: space-between;
    line-height: 8vw;

    p {
      width: 18vw;
      height: 8vw;
      background-color: #f0f0f0;
      border: 1px solid #f0f0f0;
      font-size: 13px;
    }

    .plat-two-bottom-text-active {
      background-color: #4581fb;
      color: #fff;
      border: 1px solid #4581fb;

    }
  }
}

// 第三部分 线下场景
.plat-three {
  margin-top: 20vw;
  width: 100vw;
  height: 100vw;

  .linshibtn {
    margin-top: -5vw;
    width: 188px;
  }

  // background-color: greenyellow;
  .plat-three-top {
    width: 80%;
    margin: 0 auto;
    height: 20vw;

    .plat-three-top-p1 {
      color: #000;
      font-size: 18px;
      font-weight: 600;
    }

    .plat-three-top-p2 {
      margin-top: 5vw;
      color: #919191;
      font-size: 13px;
    }
  }

  .plat-three-bottom {
    width: 100vw;
    height: 85vw;
    margin-top: 10vw;
    position: relative;

    .plat-three-bottom-p1 {
      font-weight: 600;
    }

    .plat-three-bottom-p2 {
      width: 80%;
      margin: 5vw auto;
      color: #919191;
      font-size: 13px;
    }

    .plat-three-bottom-img1 {
      width: 100vw;
      height: 40vw;
    }

    .plat-three-bottom-img2 {
      width: 28vw;
      height: 18vw;
      position: absolute;
      left: 10vw;
      top: 15vw;
    }
  }

  .plat-three-bottom-text {
    width: 100vw;
    height: 8vw;
    display: flex;
    justify-content: space-between;
    line-height: 8vw;

    p {
      width: 18vw;
      height: 8vw;
      background-color: #f0f0f0;
      border: 1px solid #f0f0f0;
      font-size: 13px;
    }

    .plat-three-bottom-text-active {
      background-color: #4581fb;
      color: #fff;
      border: 1px solid #4581fb;

    }
  }
}

// 第四部分 多场景
.plat-four {
  width: 100vw;
  height: 120vw;

  .linshibtn {
    width: 188px;
  }

  .plat-four-top {
    width: 80%;
    margin: 0 auto;
    height: 20vw;

    .plat-four-top-p1 {
      color: #000;
      font-size: 18px;
      font-weight: 600;
    }

    .plat-four-top-p2 {
      margin-top: 5vw;
      color: #919191;
      font-size: 13px;
    }
  }

  .plat-four-top-img {
    margin-top: 10vw;
    width: 100%;
    height: 40vw;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .plat-four-icon {
    list-style: none;
    width: 95%;
    height: 13vw;
    margin: 5vw auto;
    // margin-top: 5vw;
    display: flex;
    // 换行
    // flex-wrap:wrap;
    .plat-four-icon-child {
      margin-left: 3vw;
      width: 28vw;
      height: 100%;
      font-size: 13px;
      line-height: 13vw;

      img {
        width: 13vw;
        height: 100%;
      }
    }
  }
}
// 第五部分 流量分布
.plat-five {
  width: 100vw;
  height: 170vw;

  .linshibtn {
   margin-top: 10vw;
    
    width: 188px;
  }

  .plat-five-top {
    width: 80%;
    margin: 0 auto;
    height: 30vw;

    .plat-five-top-p1 {
      color: #000;
      font-size: 18px;
      font-weight: 600;
    }

    .plat-five-top-p2 {
      margin-top: 5vw;
      color: #919191;
      font-size: 13px;
    }
  }
  .plat-five-bottom{
    width: 100vw;
    height: 110vw;
    display: flex;
    list-style: none;
    flex-wrap:wrap;
    .plat-five-bottom-child{
      margin: 0 auto;
      width: 40vw;
      height: 55vw;
      .plat-five-bottom-img{
        width: 40vw;
        height: 40vw;
      }
      .plat-five-bottom-p{
        margin: 0 auto;
        border-radius: 10px;
        line-height: 10vw;
        width: 92%;
        height: 10vw;
        border: 1px solid #ccc;
      }
    }
  }
  }
  // 第七部分 广告投放
  .plat-seven{
    width: 100vw;
    height: 205vw;
    .linshibtn {
   margin-top: 10vw;
    width: 188px;
  }

  .plat-seven-top {
    width: 80%;
    margin: 0 auto;
    height: 30vw;

    .plat-seven-top-p1 {
      color: #000;
      font-size: 18px;
      font-weight: 600;
    }

    .plat-seven-top-p2 {
      margin-top: 5vw;
      color: #919191;
      font-size: 13px;
    }
  }
  .plat-seven-bottom{
    width: 100vw;
    height: 145vw;
    // display: flex;
    // justify-content: space-around;
    img{
      width: 50vw;
      height: 50vw;
    }
    .plat-seven-text{
      margin: 0 auto;
      margin-top: 5vw;
      border: 1px solid #ccc;
      width: 80vw;
      height: 25vw;
      line-height: 7vw;
      border-radius: 10px;
      transition: all 0.3s linear;
      .plat-seven-text-p1{
        margin-top: 2vw;
        font-weight: 600;
        font-size: 18px;
      }
      .plat-seven-text-p2{
        font-size: 14px;
        // color: #302f2f;
      }

    }
    .plat-seven-text-active{
      color: #fff;
      background-color: #4581fb;
      border: 1px solid #4581fb;
    }
  }
  }
  // 表单提交成功后的提示信息
.subSuccess{
  width: 100vw;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100000;
  .message{
    width: 90vw;
    height: 70vw;
    background-color: #fff;
    margin: 20vh auto;
    border-radius: 2vw;
    h2{
      margin-left: 80vw;
      color:#6d6c6c;
    }
    img{
      width: 52px;
      height: 52px;
    }
    .mss-text{
      width: 55vw;
      height: 40vw;
      margin: 5vw auto;
      .mess-text-p{
          margin-bottom: 5vw;
      }
    }
  }
}
</style>